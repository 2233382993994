import * as React from 'react';

import Layout from '../containers/Layout';
import Seo from '../components/Seo';
import useTranslation from '../hooks/useTranslation';
import Content from '../containers/Content';

function About({ pageContext: { currentPath } }) {
  const { t, translations, locale } = useTranslation();

  return (
    <Layout background="hero" title={t('SEO.ABOUT.TITLE')}>
      <Seo title={t('SEO.ABOUT.TITLE')} location={currentPath} lang={locale} />

      <Content title={t('ABOUT.TITLE')}>
        <div className="space-y-4">
          {translations.ABOUT.TEXT.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      </Content>
    </Layout>
  );
}

export default About;
